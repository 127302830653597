import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './modules/admin/admin.component';
import { AnswersComponent } from './modules/answers/answers.component';
import { InstructionsComponent } from './modules/instructions/instructions.component';
import { IntroComponent } from './modules/intro/intro.component';
import { LoginComponent } from './modules/login/login.component';
import { PodiumComponent } from './modules/podium/podium.component';
import { QuizComponent } from './modules/quiz/quiz.component';
import { RegistrationHistoryComponent } from './modules/registration-history/registration-history.component';
import { RemoteControlComponent } from './modules/remote-control/remote-control.component';
import { AuthGuard } from './services/auth.guard';
import { GridComponent } from './modules/grid/grid.component';

import { MenuComponent } from './template/menu/menu.component';
import { EventsComponent } from './modules/events/events.component';
import { EventAddComponent } from './modules/event-add/event-add.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { BrindesComponent } from './modules/brindes/brindes.component';
import { BrindesEditComponent } from './modules/brindes/brindes-edit/brindes-edit/brindes-edit.component';
import { BrindesOrderComponent } from './modules/brindes/brindes-order/brindes-order/brindes-order.component';
import { BrindesListComponent } from './modules/brindes/brindes-list/brindes-list/brindes-list.component';
import { CadastrarBannerComponent } from './modules/banner/cadastrar-banner/cadastrar-banner/cadastrar-banner.component';
import { BannerListComponent } from './modules/banner/banners-list/banner-list/banner-list.component';
import { LogPainelComponent } from './modules/log-painel/log-painel.component';
import { ListarFeatFlagComponent } from './modules/featFlag/listar-feat-flag/listar-feat-flag.component';
import { CriarFeatFlagComponent } from './modules/featFlag/criar-feat-flag/criar-feat-flag.component';
import { AdicionarFeatFlagUserComponent } from './modules/featFlag/adicionar-feat-flag-user/adicionar-feat-flag-user.component';
import PushControllerComponent from './modules/push-controller/push-controller.component';

const routes: Routes = [
  {
    path: 'admin',
    component: MenuComponent,
    children: [
      {
        path: '',
        component: AdminComponent,
        data: { title: 'Cadastrar usuário do APP' },
        canActivate: [AuthGuard],
      },
      {
        path: 'remote-control',
        component: RemoteControlComponent,
        data: { title: 'Nova rodada do QUIZ' },
        canActivate: [AuthGuard],
      },
      {
        path: 'history',
        component: RegistrationHistoryComponent,
        data: { title: 'Usuários cadastrados do APP' },
        canActivate: [AuthGuard],
      },
      {
        path: 'events',
        component: EventsComponent,
        data: { title: 'Eventos cadastrados' },
        canActivate: [AuthGuard],
      },
      {
        path: 'event-add',
        component: EventAddComponent,
        data: { title: 'Novo Evento' },
        canActivate: [AuthGuard],
      },
      {
        path: 'event-edit/:idEvento',
        component: EventAddComponent,
        data: { title: 'Novo Evento' },
        canActivate: [AuthGuard],
      },
      {
        path: 'app-brindes',
        component: BrindesComponent,
        data: { title: 'Brindes' },
        canActivate: [AuthGuard],
      },
      {
        path: 'app-brindes-edit/:idBrinde',
        component: BrindesEditComponent,
        data: { title: 'Editar brinde' },
        canActivate: [AuthGuard],
      },
      {
        path: 'app-brindes-add',
        component: BrindesEditComponent,
        data: { title: 'Novo brinde' },
        canActivate: [AuthGuard],
      },
      {
        path: 'app-brindes-order',
        component: BrindesOrderComponent,
        data: { title: 'Pedidos de brindes' },
        canActivate: [AuthGuard],
      },
      {
        path: 'app-brindes-list',
        component: BrindesListComponent,
        data: { title: 'Lista de Pedidos' },
        canActivate: [AuthGuard],
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'app-cadastrar-banner',
        data: { title: 'Cadastrar Banner' },
        component: CadastrarBannerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-banner-edit/:idBanner',
        data: { title: 'Editar Banner' },
        component: CadastrarBannerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-banner-list',
        data: { title: 'Banners Cadastrados' },
        component: BannerListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-log-painel',
        data: { title: 'Registro de ações' },
        component: LogPainelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-listar-feat-flag',
        data: { title: 'Lista de FeatFlags' },
        component: ListarFeatFlagComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-criar-feat-flag',
        data: { title: 'Criar nova FeatFlag' },
        component: CriarFeatFlagComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-adicionar-feat-flag-user',
        data: { title: 'Controle de FeatFlag' },
        component: AdicionarFeatFlagUserComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'app-push-controller',
        data: { title: 'Controle de push' },
        component: PushControllerComponent,
        canActivate: [AuthGuard],
      },
    ],
  },

  { path: '', component: IntroComponent, pathMatch: 'full' },
  { path: 'instructions', component: InstructionsComponent },
  { path: 'quiz', component: QuizComponent },
  { path: 'grid', component: GridComponent },
  { path: 'podium', component: PodiumComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
